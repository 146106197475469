import React from "react"
import SEO from "../components/seo"
import { Link } from "gatsby"
import ContactForm from "../components/Form"
import IVLogo from "../images/IV-Solution-Logo.svg"
import Checkmark from "../images/checkmark.svg"
import Carousel from "nuka-carousel"

const Logo = () => <><img style={{ width: 58 }} src={IVLogo} alt='IV Solutions Logo' /></>
const CheckMark = () => <><img style={{ maxWidth: 30, height: 'auto', paddingBottom: '10px', marginRight: 10 }} src={Checkmark} alt='checkmark' /></>
function goBack() {
  window.history.go(-1)
}


const SecondPage = () => (
  <>
    <SEO title="Schedule an In-Office Consultation" />
    <div className="hero-wrapper consultation" >
     <div className="btn btn-secondary back-button with-shadow" onClick={goBack}>Back</div>
      <div className="consultation-header">
        <div>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <h2 className="is-purple mt-4">Schedule an In-Office Consultation</h2>
        <h3 className="is-purple subheader-consultation">Take the first step to regaining energy, mental clarity, sexual health and so much more. Request an in-office consultation with our amazing Team by calling us or filling out the form below.</h3>
      </div>
      <div className="consultation-wrapper p-3">
        < ContactForm />
        <div className="usp-testimonials-wrapper">
          <div className="bg-white p-3 usp-wrapper mb-3 with-shadow">
            <h3 className="is-purple p-1 pl-3 font-weight-bold text-center">What Happens During Consultation</h3>
            <table className="is-purple font-weight-normal">
              <tbody>
                <tr>
                  <td>
                    <div className="pl-2 d-flex items-center">
                    <CheckMark />
                    <p className="pl-1">You will meet with the doctor &amp; discuss your symptoms</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="pl-2 d-flex items-center">
                    <CheckMark />
                    <p className="pl-1">We will do a comprehensive review of your medical history</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="pl-2 d-flex items-center">
                    <CheckMark />
                    <p className="pl-1">We will educate you about neurological health &amp; ketamine</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="pl-2 d-flex items-center">
                    <CheckMark />
                    <p className="pl-1">We will answer any questions you may have</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="bg-white p-3 usp-wrapper mt-3 with-shadow">
            <h3 className="is-purple p-1 pl-3 font-weight-bold text-center">What Our Patients Say</h3>
            <div>
            <Carousel
              width={'100%'}
              renderCenterLeftControls={() => null}
              renderCenterRightControls={() => null}
              autoplay={true}
              pauseOnHover={true}
              swiping={true}
              autoplayInterval={5000}
              wrapAround = {true}
              className='p-3'
            >
              <div>
                <p className="is-purple pl-3">"The ketamine infusions have been a miracle in my quest to overcome depression.  It has truly brought me back to normal and I can tell that the effects are lasting."</p>
                <h3 className="is-purple pl-3 font-weight-bold">Jim C.</h3>
              </div>
              <div>
                <p className="is-purple pl-3">"My treatment consisted of six infusions over a two-week period. I felt slightly better after just one infusion. Since completing the infusions I can honestly say that I am a different person."</p>
                <h3 className="is-purple pl-3 font-weight-bold">Dena</h3>
              </div>
              <div>
                <p className="is-purple pl-3">"After reading a lot of information on the results of ketamine treatments I decided to give it a shot. I can’t tell you what a difference it has made in my life!"</p>
                <h3 className="is-purple pl-3 font-weight-bold">Jason P.</h3>
              </div>
              <div>
                <p className="is-purple pl-3">"Well, the results have exceeded my expectations - not only has my neuropathy pain been significantly reduced, the symptoms of depression have lifted and I feel terrific!"</p>
                <h3 className="is-purple pl-3 font-weight-bold">Steve G</h3>
              </div>
            </Carousel>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="overlay"></div> */}
      <div className="overlay-white"></div>
    </div>
  </>
)

export default SecondPage
